
$font-size-base: 0.75rem !default; // Assumes the browser default, typically `16px`
$line-height-base: 1;

//$font-family: Sans-serif;
$lightgrey: #F2F2FF;
$darkgrey: #8F8F9C;
$lightBlue: rgba(27,153,232,1);
$darkBlue: rgba(6,33,52,1);
$white: #fff;
$grey: #ccc;

$enable-cssgrid: true
