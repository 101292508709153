p {
  line-height: $line-height-base * 1.5;
}

.content-wrapper{
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.15);
  z-index:1;
  min-height: calc((100vh - 65px)/2);
  padding: 0;
}

#ColumnWrap{
  margin-left: 0 !important;
  margin-right: 0 !important;
  min-height: calc(100vh - 65px);
}


.map-wrapper{
  height: calc((100vh - 65px)/2);
  z-index: 0;
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #ColumnWrap{
    .content-wrapper{
      transition: all 0.5s ease-in-out;
    }
    .expand-button .fa:before{
        content: "\f054";
    }
    &.expand{
      .content-wrapper{
        flex: 0 0 100%;
        max-width: 100%;
      }
      .expand-button .fa:before{
          content: "\f053";
      }
    }
  }
  .content-wrapper{
    overflow-y: auto;
      overflow-x: hidden;
      height: calc(100vh - 65px);
  }

  .map-wrapper{
    height: calc(100vh - 65px);
    .map{
      overflow:hidden /* very strange */
    }
  }

}


.breadcrumb-wrapper {
  .expand-button{
    text-align: right;
  }
}

.compliance-wrapper{
  background:$lightgrey;
  span{
    line-height:35px;
  }
}

.p-breadcrumb {
  padding: 0;
  border: none;
  border-radius: 0;
  background: transparent;
  display: inline-block;
  padding: .75rem;
}

.mainPanel > .p-panel{
  padding: 0;
  .p-panel-content {
    padding: 0;
  }
  > .p-panel-content {
    border: none;
    background: transparent;
    border-radius: 0;
  }
}

.p-tabview .p-tabview-panels {
  padding: 0;
}

.logo-wrapper{
     background-color: $white;
     border-radius: 0.25rem;
     .img-thumbnail {
      padding: 0;
      background-color: transparent;
      border: none;
      border-radius: 0.25rem;
      height: 35px;
  }
}

#AssessmentsWrap, #AssessmentHistoryWrap, #ActivitiesWrap, .equipment-wrap{
  background: $white;
  padding: 1rem 0;
  align-items: center;
  .table td{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

#CalendarWrap {
  background: $white;
  padding: 1rem;

  .assessment-event {
    cursor: pointer;

    &.assessment-event-impact-000000 {
      background-color: #000000;
      border-color: darken(#000000, 5%);
    }
    &.assessment-event-impact-0000FF {
      background-color: #0000FF;
      border-color: darken(#0000FF, 5%);
    }
    &.assessment-event-impact-00FF00 {
      background-color: #00FF00;
      border-color: darken(#00FF00, 5%);
    }
    &.assessment-event-impact-00FFFF {
      background-color: #00FFFF;
      border-color: darken(#00FFFF, 5%);
    }
    &.assessment-event-impact-000080 {
      background-color: #000080;
      border-color: darken(#000080, 5%);
    }
    &.assessment-event-impact-008000 {
      background-color: #008000;
      border-color: darken(#008000, 5%);
    }
    &.assessment-event-impact-008080 {
      background-color: #008080;
      border-color: darken(#008080, 5%);
    }
    &.assessment-event-impact-800000 {
      background-color: #800000;
      border-color: darken(#800000, 5%);
    }
    &.assessment-event-impact-800080 {
      background-color: #800080;
      border-color: darken(#800080, 5%);
    }
    &.assessment-event-impact-808000 {
      background-color: #808000;
      border-color: darken(#808000, 5%);
    }
    &.assessment-event-impact-808080 {
      background-color: #808080;
      border-color: darken(#808080, 5%);
    }
    &.assessment-event-impact-B19CD9 {
      background-color: #B19CD9;
      border-color: darken(#B19CD9, 5%);
    }
    &.assessment-event-impact-C0C0C0 {
      background-color: #C0C0C0;
      border-color: darken(#C0C0C0, 5%);
    }
    &.assessment-event-impact-F5F5F5 {
      background-color: #F5F5F5;
      border-color: darken(#F5F5F5, 5%);
    }
    &.assessment-event-impact-FF0000 {
      background-color: #FF0000;
      border-color: darken(#FF0000, 5%);
    }
    &.assessment-event-impact-FF00FF {
      background-color: #FF00FF;
      border-color: darken(#FF00FF, 5%);
    }
    &.assessment-event-impact-FFBF00 {
      background-color: #FFBF00;
      border-color: darken(#FFBF00, 5%);
    }
    &.assessment-event-impact-FFFF00 {
      background-color: #FFFF00;
      border-color: darken(#FFFF00, 5%);
    }
    &.assessment-event-impact-FFFFFF {
      background-color: #FFFFFF;
      border-color: darken(#FFFFFF, 5%);
    }
  }
}

.submission{
  border: 1px dotted $grey;
  padding: 1.5rem;
  margin: 1.5rem 0;
  align-items: center;
  h3{
    margin-bottom: 0;
  }
}

.equipment-summary {
  background: $lightgrey;
  border-radius: 0.25rem;
  margin: 1rem 0 2rem;
  text-align: center;

  .activity-total {
    border-top: 1px solid $grey;
    text-align: left;
  }

  .equipment-name {
    text-align: left;
  }

  .equipment-wrap {
    padding: 0;
  }
}

footer{
  font-size: $font-size-sm;
}
