@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import '../../node_modules/primeng/resources/themes/lara-light-blue/theme.css';
@import "layout";

html {
  font-size: 14px;
}

.bg-light {
  background-color: $lightBlue!important;
}
.bg-dark {
  background-color: $darkBlue!important;
}

.text-lightblue {
  color: $lightBlue;
}

.text-darkblue {
  color: $darkBlue;
}

p-autocomplete {
  span > input {
      line-height: normal;
  }
}
p-calendar,
p-calendar > span,
p-calendar > span > input {
  width: 100%;
}

td.multiselect-host {
  padding-top: 0.3rem;
  padding-right: 0.3rem;
  padding-left: 0.3rem;

  p-multiselect .ui-multiselect {
    line-height: 0.9rem;
    font-size: $font-size-sm;
    width: 100%;

    label {
        margin-bottom: 0rem;
    }
  }
}

ul.p-dropdown-items,ul.p-multiselect-items {
  padding-left: 0px;
}

.app-not-implemented {
  pointer-events: none;
  cursor: default;
  color: lightgray !important;
}

.required label:after {
  content:"*";
  color:red;
}

/* Menu Overrides */
.col-centered {
  margin-top: auto;
  margin-bottom: auto;
}

.col-small {
  padding-left: 5px;
  padding-right: 5px;
}

.p-multiselect.p-component{
  width:100%
}

/* Tabview Overrides */
.p-tabview-nav-link {
  font-size: 0.8rem;
  text-decoration: none;
  color: $lightBlue;
}

.p-highlight > .p-tabview-nav-link {
  border-color: $lightBlue;
}

.p-menu-list {
  padding: 0;
  margin: 0;

  .p-submenu-header {
    padding: 0px;
  }

  .p-element {
    text-decoration: none;
  }
}

/* Dropdown Overrides */
.p-dropdown.form-control {
  display: flex;
  padding: 0;
}

/* Bootstrap reset Overrides */
.p-tabview-nav {
  padding-left: 0;
}

.p-breadcrumb-list {
  padding-left: 0;
  margin-bottom: 0;
}

.p-button {
  padding: 0.375rem 0.75rem;
}
